import { Tooltip } from "@fluentui/react-components";
import { Sparkle28Filled } from "@fluentui/react-icons";

import styles from "./Answer.module.css";
import i18next from "../../i18n/i18n";
import { appInfo, userInfo } from "../../authConfig";

interface Props {
    product: { manual_key: string; faq_key: string } | null;
    sub_product: { manual_sub_key: string; faq_sub_key: string } | null;
    productVersion: { manual_version_key: string; faq_version_key: string } | null;
    language: string | null;
    documenttype?: string | null;
    chatModel: string | null;
    feedbackCorpId: string | null;
    feedbackUser: string | null;
    CorpId: string | null | undefined;
    UserId: string | null | undefined;
    isFeedbackPage: boolean | undefined;
    apptype: string | null;
}

export const AnswerIcon = ({
    language,
    product,
    sub_product,
    documenttype,
    chatModel,
    feedbackCorpId,
    feedbackUser,
    CorpId,
    UserId,
    isFeedbackPage,
    productVersion,
    apptype
}: Props) => {
    const tooltipArr = [];
    const selectedLocale = sessionStorage.getItem("selectedLocale") ?? "ja";
    const selected_product = appInfo.product_categories[selectedLocale]?.filter(
        (item: any) => (item.manual_key && item.manual_key === product?.manual_key) || (item.faq_key && item.faq_key === product?.faq_key)
    )[0];
    product = selected_product?.text ?? "";
    if (language) {
        tooltipArr.push(i18next.t("chat.language") + ": " + language);
    }
    if (product) {
        tooltipArr.push(i18next.t("chat.productFilterTitle") + ": " + product);
    }
    if (selected_product?.is_sub_only === "true") {
        const subVersion = selected_product?.versions?.filter(
            (sv: any) =>
                (sv.manual_sub_key && sv.manual_sub_key === sub_product?.manual_sub_key) || (sv.faq_sub_key && sv.faq_sub_key === sub_product?.faq_sub_key)
        )[0];
        if (subVersion) {
            const sub_versions = sub_product?.manual_sub_key === "0" || sub_product?.faq_sub_key === "0" ? "ALL" : subVersion?.text ?? "";
            tooltipArr.push(i18next.t("chat.subProduct") + ": " + sub_versions);
        }
    } else if (selected_product?.versions && productVersion) {
        const selectedVersion = selected_product?.versions?.filter(
            (item: any) =>
                (item.manual_version_key && item.manual_version_key === productVersion.manual_version_key) ||
                (item.faq_version_key && item.faq_version_key === productVersion.faq_version_key)
        )[0];
        const versions = productVersion.manual_version_key === "0" || productVersion.faq_version_key === "0" ? "ALL" : selectedVersion?.text ?? "";
        if (versions) {
            tooltipArr.push(i18next.t("chat.version") + ": " + versions);
        }
        if (sub_product) {
            const selectedSubVersion = selectedVersion?.sub_versions?.filter(
                (item: any) =>
                    (item.faq_sub_key && item.faq_sub_key === sub_product.faq_sub_key) ||
                    (item.manual_sub_key && item.manual_sub_key === sub_product.manual_sub_key)
            )[0];
            const sub_versions = sub_product.manual_sub_key === "0" || sub_product.faq_sub_key === "0" ? "ALL" : selectedSubVersion?.text ?? "";
            if (sub_versions) {
                if ((sub_product.manual_sub_key !== "" && apptype === "Manuals") || (sub_product.faq_sub_key !== "" && apptype === "FAQ")) {
                    tooltipArr.push(i18next.t("chat.subProduct") + ": " + sub_versions);
                }
            }
        }
    }
    if (userInfo.isWap == "true" || userInfo.isWap == "True") {
        if (documenttype) {
            tooltipArr.push(i18next.t("chat.documenttype") + ": " + i18next.t(`chat.${documenttype}`));
        }
        if (chatModel) {
            tooltipArr.push(i18next.t("chat.chatModel") + ": " + chatModel);
        }
    }
    if (feedbackCorpId) {
        tooltipArr.push(i18next.t("chat.feedbackCorpId") + ": " + feedbackCorpId);
    }
    if (feedbackUser) {
        tooltipArr.push(i18next.t("chat.feedbackuser") + ": " + feedbackUser);
    }
    if (isFeedbackPage) {
        if (CorpId) {
            tooltipArr.push(i18next.t("chat.corpId") + ": " + CorpId);
        }
        if (UserId) {
            tooltipArr.push(i18next.t("chat.userId") + ": " + UserId);
        }
    }
    return (
        <Tooltip
            content={{
                children: tooltipArr.join(", "),
                className: styles.answerIconTooltip
            }}
            relationship="label"
        >
            <Sparkle28Filled primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true" aria-label="Answer logo" />
        </Tooltip>
    );
};
