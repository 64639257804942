import React, { useState, useRef, useEffect } from "react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
initializeIcons(undefined, { disableWarnings: true });
import { DefaultButton, Text } from "@fluentui/react";
import { SessionInfo, YearData } from "../../api";
import i18next from "../../i18n/i18n";
import { Box, Drawer, Skeleton, styled } from "@mui/material";
import { makeStyles, shorthands, Tab } from "@fluentui/react-components";
import { useMemo } from "react";
import { ArrowSyncRegular } from "@fluentui/react-icons";
import CircularProgress from "@mui/material/CircularProgress";
interface Props {
    nextDate: string;
    sourcePage: string;
    isLoading: boolean;
    currentIndex: string;
    lastSessionRef?: any;
    isSidebarOpen: boolean;
    lastActiveIndexRef?: any;
    lastActiveSessionRef?: any;
    todaySessionsRef: SessionInfo[];
    last7SessionsRef: SessionInfo[];
    last30SessionsRef: SessionInfo[];
    monthlySessions: YearData[];
    loadChatData: (session_id: string) => void;
    setCurrentIndex: (session_id: string) => void;
    loadSessionsData: () => void;
}

const monthNames = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December"
};

const useStyles = makeStyles({
    root: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        ...shorthands.padding("10px", "20px"),
        rowGap: "10px",
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "30px",
        paddingRight: "20px"
    },
    tabStyle: {
        color: "white",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        lineHeight: 2,
        fontSize: "15px",
        cursor: "pointer",
        ":active": {
            fontWeight: "bold"
        },
        ":selected": {
            fontWeight: "bold"
        },
        ":hover": {
            fontWeight: "bold"
        },
        ":target": {
            fontWeight: "bold"
        }
    },
    year: { fontFamily: "math", minHeight: "40px !important", fontSize: "25px" },
    month: { fontFamily: "math", minHeight: "40px !important", fontSize: "20px" }
});

const groupSessionsByYearAndMonth = (data: YearData[]): any => {
    const grouped: any = {};

    data?.forEach(yearData => {
        const { year, sessions } = yearData;

        if (!grouped[year]) {
            grouped[year] = {};
        }

        sessions?.forEach(session => {
            let { monthName, records } = session;
            monthName = getTranslatedMonth(monthName);
            if (!grouped[year][monthName]) {
                grouped[year][monthName] = [];
            }
            records?.forEach((record: any) => {
                const isDuplicate = grouped[year][monthName].some(
                    (existingRecord: any) => existingRecord.sessionId === record.sessionId && existingRecord.conversation_date === record.conversation_date
                );

                if (!isDuplicate) {
                    grouped[year][monthName].push(record);
                }
            });
        });
    });
    return grouped;
};

const getTranslatedMonth = (month: string) => {
    switch (month) {
        case monthNames.Jan:
            return i18next.t("monthNames.Jan");
        case monthNames.Feb:
            return i18next.t("monthNames.Feb");
        case monthNames.Mar:
            return i18next.t("monthNames.Mar");
        case monthNames.Apr:
            return i18next.t("monthNames.Apr");
        case monthNames.May:
            return i18next.t("monthNames.May");
        case monthNames.Jun:
            return i18next.t("monthNames.Jun");
        case monthNames.Jul:
            return i18next.t("monthNames.Jul");
        case monthNames.Aug:
            return i18next.t("monthNames.Aug");
        case monthNames.Sep:
            return i18next.t("monthNames.Sep");
        case monthNames.Oct:
            return i18next.t("monthNames.Oct");
        case monthNames.Nov:
            return i18next.t("monthNames.Nov");
        case monthNames.Dec:
            return i18next.t("monthNames.Dec");
        default:
            return month;
    }
};

export const Sidebar = (props: Props) => {
    const tabstyles = useStyles();
    const [showGroupedSessions, setShowGroupedSessions] = useState(false);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [monthGroupedSessions, setMonthGroupedSessions] = useState<any>();
    const calculateDrawerWidth = () => {
        const viewportWidth = window.innerWidth;
        return viewportWidth <= 1400 ? "20vh" : "30vh";
    };

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-start"
    }));

    const handleOnClickOfSessionId = (item: SessionInfo, activeSession: any) => {
        const urlParams = new URLSearchParams();
        urlParams.append("SessionId", item.session_id ?? "");
        history.replaceState({}, "", "?" + urlParams.toString());
        props.setCurrentIndex(item.session_id ?? "");
        props.loadChatData(item.session_id ?? "");
        if (props.sourcePage === "chat") {
            props.lastActiveIndexRef.current = item.group;
            props.lastSessionRef.current = item;
            props.lastActiveSessionRef.current = activeSession;
            sessionStorage.setItem("sessionId", item.session_id ?? "");
        }
    };

    useMemo(() => {
        const results = groupSessionsByYearAndMonth([...props.monthlySessions]);
        setMonthGroupedSessions(results);
    }, [props.todaySessionsRef, props.last7SessionsRef, props.last30SessionsRef, props.monthlySessions]);

    useEffect(() => {
        if (window.location.pathname === "/assistant/chat") {
            setTimeout(() => {
                setIsLoading(false);
            }, 3000);
        } else {
            setIsLoading(false);
        }
    }, [props.todaySessionsRef, props.last7SessionsRef, props.last30SessionsRef, props.monthlySessions]);

    const loadMoreSessions = () => {
        props.loadSessionsData();
        setIsLoading(true);
        setShowGroupedSessions(true);
    };

    const EmptySkeletons = () => {
        return (
            <div className={tabstyles.root}>
                <Box sx={{ width: "100%" }}>
                    <Skeleton animation="wave" sx={{ width: "100%" }} />
                    <Skeleton animation="wave" sx={{ width: "90%" }} />
                    <Skeleton animation="wave" sx={{ width: "80%" }} />
                    <Skeleton animation="wave" sx={{ width: "70%" }} />
                </Box>
            </div>
        );
    };

    return (
        <Drawer
            ref={sidebarRef}
            id="sidebar"
            open={props.isSidebarOpen}
            variant="persistent"
            anchor="left"
            sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: calculateDrawerWidth(),
                    boxSizing: "border-box",
                    background: "#e3e3e3",
                    fontWeight: 650,
                    transition: "all 0.5s",
                    position: "relative",
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: innerHeight - 85,
                    zIndex: 0
                }
            }}
            ModalProps={{
                keepMounted: false
            }}
        >
            {Number(props.todaySessionsRef.length) === 0 &&
            Number(props.last7SessionsRef.length) === 0 &&
            Number(props.last30SessionsRef.length) === 0 &&
            Object.keys(monthGroupedSessions ?? {}).length === 0 ? (
                <div
                    style={{
                        boxSizing: "border-box",
                        background: "#e3e3e3",
                        position: "relative",
                        overflowY: "auto",
                        overflowX: "hidden",
                        top: "1px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: innerHeight - 97,
                        transition: "all 0.5s",
                        zIndex: 0
                    }}
                >
                    <p>{i18next.t("layout.NoDataAvailable")}</p>
                </div>
            ) : (
                <>
                    {!isLoading && props.todaySessionsRef?.length > 0 && Object.keys(props.todaySessionsRef[0] ?? {}).length !== 0 ? (
                        <>
                            <DrawerHeader>{i18next.t("chat.today")}</DrawerHeader>
                            <div className={tabstyles.root}>
                                {props.todaySessionsRef.map((item, index) => (
                                    <Tab
                                        className={tabstyles.tabStyle}
                                        key={item.session_id + "_" + index}
                                        value={item.session_id}
                                        title={item.title}
                                        onClick={() => handleOnClickOfSessionId(item, props.todaySessionsRef)}
                                        disabled={props.isLoading}
                                    >
                                        <Text
                                            style={{
                                                fontWeight: props.currentIndex === item.session_id ? "bold" : "normal",
                                                fontSize: "medium",
                                                background:
                                                    props.currentIndex === item.session_id
                                                        ? `linear-gradient(90deg,none,none 84%,transparent 89%,transparent)`
                                                        : "none",
                                                padding: "4px",
                                                borderRadius: "20px",
                                                backgroundColor: props.currentIndex === item.session_id ? "#b4b4b4" : "none"
                                            }}
                                        >
                                            {item.title}
                                        </Text>
                                    </Tab>
                                ))}
                            </div>
                        </>
                    ) : (
                        isLoading &&
                        window.location.pathname === "/assistant/chat" && (
                            <Box>
                                <DrawerHeader>{i18next.t("chat.today")}</DrawerHeader>
                                <EmptySkeletons />
                            </Box>
                        )
                    )}

                    {!isLoading && props.last7SessionsRef?.length > 0 && Object.keys(props.last7SessionsRef[0] ?? {}).length !== 0 ? (
                        <>
                            <DrawerHeader>{i18next.t("chat.last7days")}</DrawerHeader>
                            <div className={tabstyles.root}>
                                {props.last7SessionsRef.map((item, index) => (
                                    <Tab
                                        className={tabstyles.tabStyle}
                                        key={item.session_id + "_" + index}
                                        value={item.session_id}
                                        title={item.title}
                                        onClick={() => handleOnClickOfSessionId(item, props.last7SessionsRef)}
                                        disabled={props.isLoading}
                                    >
                                        <Text
                                            style={{
                                                fontWeight: props.currentIndex === item.session_id ? "bold" : "normal",
                                                fontSize: "medium"
                                            }}
                                        >
                                            {item.title}
                                        </Text>
                                    </Tab>
                                ))}
                            </div>
                        </>
                    ) : (
                        isLoading &&
                        window.location.pathname === "/assistant/chat" && (
                            <Box>
                                <DrawerHeader>{i18next.t("chat.last7days")}</DrawerHeader>
                                <EmptySkeletons />
                            </Box>
                        )
                    )}

                    {!isLoading && props.last30SessionsRef?.length > 0 && Object.keys(props.last30SessionsRef[0] ?? {}).length !== 0 ? (
                        <>
                            <DrawerHeader>{i18next.t("chat.last30days")}</DrawerHeader>
                            <div className={tabstyles.root}>
                                {props.last30SessionsRef.map((item, index) => (
                                    <Tab
                                        className={tabstyles.tabStyle}
                                        key={item.session_id + "_" + index}
                                        value={item.session_id}
                                        title={item.title}
                                        onClick={() => handleOnClickOfSessionId(item, props.last30SessionsRef)}
                                        disabled={props.isLoading}
                                    >
                                        <Text
                                            style={{
                                                fontWeight: props.currentIndex === item.session_id ? "bold" : "normal",
                                                fontSize: "medium"
                                            }}
                                        >
                                            {item.title}
                                        </Text>
                                    </Tab>
                                ))}
                            </div>
                        </>
                    ) : (
                        isLoading &&
                        window.location.pathname === "/assistant/chat" && (
                            <Box>
                                <DrawerHeader>{i18next.t("chat.last30days")}</DrawerHeader>
                                <EmptySkeletons />
                            </Box>
                        )
                    )}

                    {((props.sourcePage === "chat" && showGroupedSessions) ||
                        props.sourcePage !== "chat" ||
                        (props.sourcePage === "chat" &&
                            Object.keys(props.todaySessionsRef[0] ?? {}).length === 0 &&
                            Object.keys(props.last7SessionsRef[0] ?? {}).length === 0 &&
                            Object.keys(props.last30SessionsRef[0] ?? {}).length === 0)) &&
                        Object.keys(monthGroupedSessions ?? {}).length > 0 &&
                        (!isLoading ? (
                            <React.Fragment>
                                {Object.keys(monthGroupedSessions)
                                    ?.sort()
                                    .reverse()
                                    .map((year, index) => (
                                        <div key={`year-${index}`}>
                                            {year !== new Date().getFullYear().toString() && (
                                                <>
                                                    <br />
                                                    <DrawerHeader className={tabstyles.year} style={{ textDecoration: "underline" }}>
                                                        {year}
                                                    </DrawerHeader>
                                                </>
                                            )}
                                            {Object.keys(monthGroupedSessions[year])?.map((month, idx) => (
                                                <div key={`month-${index}-${idx}`}>
                                                    <DrawerHeader className={tabstyles.month}>{month}</DrawerHeader>
                                                    <div className={tabstyles.root}>
                                                        {monthGroupedSessions[year][month]?.map((item: any, index: number) => (
                                                            <Tab
                                                                className={tabstyles.tabStyle}
                                                                key={item.session_id + "_" + index}
                                                                value={item.session_id}
                                                                title={item.title}
                                                                onClick={() => handleOnClickOfSessionId(item, monthGroupedSessions[year][month])}
                                                                disabled={props.isLoading}
                                                            >
                                                                <Text
                                                                    style={{
                                                                        fontWeight: props.currentIndex === item.session_id ? "bold" : "normal",
                                                                        fontSize: "medium"
                                                                    }}
                                                                >
                                                                    {item.title}
                                                                </Text>
                                                            </Tab>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                {props.nextDate !== "" && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            padding: "10px"
                                        }}
                                    >
                                        <DefaultButton
                                            style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#7376e1",
                                                color: "white",
                                                border: "none",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                                width: "auto"
                                            }}
                                            onClick={loadMoreSessions}
                                        >
                                            {isLoading ? (
                                                <CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
                                            ) : (
                                                <ArrowSyncRegular style={{ marginRight: "5px" }} />
                                            )}
                                        </DefaultButton>
                                    </div>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Box sx={{ height: window.innerHeight }}>
                                    <DrawerHeader>{i18next.t("chat.year")}</DrawerHeader>
                                    <div className={tabstyles.root}>
                                        {Array.from({ length: Math.round(window.innerHeight / 28) }, (_, i) => i + 1).map(item => (
                                            <Tab
                                                className={tabstyles.tabStyle}
                                                key={i18next.t("chat.year") + "_" + item}
                                                value={i18next.t("chat.year") + "_" + item}
                                                disabled={props.isLoading}
                                            >
                                                <Text>
                                                    <Skeleton animation="wave" sx={{ width: calculateDrawerWidth() }} />
                                                </Text>
                                            </Tab>
                                        ))}
                                    </div>
                                    {props.nextDate !== "" && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "10px"
                                            }}
                                        >
                                            <DefaultButton
                                                style={{
                                                    padding: "10px 20px",
                                                    backgroundColor: "#7376e1",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: "4px",
                                                    cursor: "pointer",
                                                    width: "auto"
                                                }}
                                                onClick={loadMoreSessions}
                                            >
                                                {isLoading ? (
                                                    <CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
                                                ) : (
                                                    <ArrowSyncRegular style={{ marginRight: "5px" }} />
                                                )}
                                            </DefaultButton>
                                        </div>
                                    )}
                                </Box>
                            </React.Fragment>
                        ))}
                </>
            )}
        </Drawer>
    );
};
