import { Text, Button } from "@fluentui/react-components";
import { ChatAdd20Regular } from "@fluentui/react-icons";
import i18next from "../../i18n/i18n";
import styles from "./../../pages/chat/Chat.module.css";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

export const ClearChatButton = ({ className, disabled, onClick }: Props) => {
    return (
        <div
            id="ClearChat"
            className={styles.commandContainerIconBtn}
            title={i18next.t("clearChat.newChat")}
            style={{
                display: "flex",
                justifyContent: "center",
                border: "2px solid rgba(0,0,0,.1)",
                borderRadius: "20px",
                padding: "2px",
                margin: "0px 8px 16px 8px"
            }}
        >
            <Button size="medium" disabled={disabled} onClick={onClick}>
                <ChatAdd20Regular style={{ background: "none", color: "#7376e1", width: "18px", height: "18px" }} />
                <Text style={{ padding: "0.25rem 0.5rem", fontSize: "12px" }}>{i18next.t("clearChat.newChat")}</Text>
            </Button>
        </div>
    );
};
