import { Stack } from "@fluentui/react";
import { Tooltip } from "@fluentui/react-components";
import {
    AlertBadge24Filled,
    ChartMultiple24Regular,
    ChatAdd28Regular,
    Checkmark24Filled,
    HistoryFilled,
    NavigationFilled,
    PersonFeedback28Regular,
    QuestionCircle24Filled,
    Settings28Regular
} from "@fluentui/react-icons";

import { AvTimer, Logout, MenuBook, RateReview } from "@mui/icons-material";
import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { Locale, saveUserDetails } from "../../api";
import localeChangeImg from "../../assets/translation.png";
import wap from "../../assets/wap.svg";
import { appInfo, jwtToken, supportEnvHost, userInfo } from "../../authConfig";
import i18next from "../../i18n/i18n";
import styles from "./Layout.module.css";

type TokenValues = {
    isInchargeOfAiWorks: string;
    userSupportId: string;
};

interface Props {
    callback: () => void;
    startTour: (start: boolean) => void;
    openSidebarCallback: (open: boolean) => void;
    isSidebarOpen: boolean;
}

export let userSupportId = "bot@ai-works";

const Layout = ({ callback, startTour, openSidebarCallback, isSidebarOpen }: Props) => {
    const tokenValues = jwtDecode(jwtToken || "") as TokenValues;
    const isAdmin = tokenValues["isInchargeOfAiWorks"] === "True";
    userSupportId = tokenValues["userSupportId"];

    const localeStr: string = userInfo.locale || Locale.JA;
    sessionStorage.setItem("selectedLocale", userInfo.locale || Locale.JA);
    // Type assertion
    const localeEnum: Locale = localeStr as Locale;
    const [locale, setLocale] = useState<Locale>(localeEnum);
    const [menuListItems, setMenuItemList] = useState<any>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const locales = appInfo.locales;

    const localeOptions = Object.keys(locales).map(key => ({
        key: key.toLowerCase(),
        text: locales[key],
        selected: locale === key,
        data: key
    }));
    const onLocaleChange = (option: any) => {
        handleClose();
        setLocale(option?.data ?? Locale.JA);
        sessionStorage.setItem("selectedLocale", option?.data ?? Locale.JA);
        saveUserSettings({ locale: option?.data ?? Locale.JA }, true);
        userInfo.locale = option?.data ?? Locale.JA;
    };

    const saveUserSettings = async (settings: object, reloadNeeded?: boolean) => {
        try {
            await saveUserDetails(settings)
                .then(response => {
                    return response.json();
                })
                .then(() => {
                    if (reloadNeeded) {
                        window.location.reload();
                    }
                })
                .catch(error => {
                    console.error("Error saving user data:", error);
                });
        } catch (error) {
            console.error("Error saving user data:", error);
        }
    };

    const iconStyle = {
        width: "30px",
        height: "30px",
        padding: "5px"
    };
    const handleClick = () => {
        callback();
    };
    const handleLogout = () => {
        Cookies.remove("token", { path: "/" });
        Cookies.remove("token", { path: "/assistant" });
        window.location.href = supportEnvHost.split("/aiworks.do")[0] + "/view/wap/top";
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTourStart = () => {
        startTour(true);
    };

    const handleDrawerOpen = () => {
        const newisSidebarOpen = !isSidebarOpen;
        openSidebarCallback(newisSidebarOpen);
        userInfo.isSidebarOpen = newisSidebarOpen.toString();
        saveUserSettings({ isSidebarOpen: newisSidebarOpen.toString() });
    };

    useEffect(() => {
        const menuListItemValues: any = [
            {
                key: i18next.t("layout.chat"),
                item: (
                    <MenuItem
                        key={i18next.t("layout.chat")}
                        onClick={() => {
                            handleClick();
                            handleClose();
                        }}
                        component={NavLink}
                        to="/assistant/chat"
                    >
                        <ListItemIcon>
                            <ChatAdd28Regular style={{ backgroundColor: "none", color: "#3a81d4" }} />
                        </ListItemIcon>
                        {i18next.t("layout.chat")}
                    </MenuItem>
                )
            },
            {
                key: i18next.t("layout.feedbacks"),
                item: (
                    <MenuItem
                        key={i18next.t("layout.feedbacks")}
                        onClick={() => {
                            handleClick();
                            handleClose();
                        }}
                        component={NavLink}
                        to="/assistant/feedbacks"
                    >
                        <ListItemIcon>
                            <PersonFeedback28Regular style={{ backgroundColor: "none", color: "#3a81d4" }} />
                        </ListItemIcon>
                        {i18next.t("layout.feedbacks")}
                    </MenuItem>
                )
            },
            {
                key: i18next.t("layout.reports"),
                item: (
                    <MenuItem
                        key={i18next.t("layout.reports")}
                        onClick={() => {
                            handleClick();
                            handleClose();
                        }}
                        component={NavLink}
                        to="/assistant/reports"
                    >
                        <ListItemIcon>
                            <ChartMultiple24Regular style={{ backgroundColor: "none", color: "#3a81d4" }} />
                        </ListItemIcon>
                        {i18next.t("layout.reports")}
                    </MenuItem>
                )
            },
            {
                key: i18next.t("layout.settings"),
                item: (
                    <MenuItem
                        key={i18next.t("layout.settings")}
                        onClick={() => {
                            handleClick();
                            handleClose();
                        }}
                        component={NavLink}
                        to="/assistant/settings"
                    >
                        <ListItemIcon>
                            <Settings28Regular style={{ backgroundColor: "none", color: "#3a81d4" }} />
                        </ListItemIcon>
                        {i18next.t("layout.settings")}
                    </MenuItem>
                )
            },
            {
                key: i18next.t("layout.updates"),
                item: (
                    <div>
                        <MenuItem
                            key={i18next.t("layout.updates")}
                            onClick={() => {
                                handleClick();
                                handleClose();
                            }}
                            target="_blank"
                            component={NavLink}
                            to="/assistant/updates"
                        >
                            <ListItemIcon>
                                <AlertBadge24Filled style={{ backgroundColor: "none", color: "#3a81d4" }} />
                            </ListItemIcon>
                            {i18next.t("layout.updates")}
                        </MenuItem>
                        <Divider />
                    </div>
                )
            },
            {
                key: i18next.t("chat.language"),
                item: (
                    <div onClick={event => event.stopPropagation()} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Stack horizontal horizontalAlign="start" style={{ width: "100%", padding: "6px 16px" }}>
                            <img src={localeChangeImg} style={{ width: 28, height: 28, marginRight: "8px" }} onClick={event => event.stopPropagation()} />
                            <span>{i18next.t("chat.language")}</span>
                        </Stack>
                        <Stack verticalFill verticalAlign="center">
                            {localeOptions.map(option => (
                                <MenuItem
                                    key={option.key}
                                    onClick={() => {
                                        onLocaleChange(option);
                                    }}
                                >
                                    <Box sx={{ width: "30px" }}>
                                        {locale === option.key ? <Checkmark24Filled style={{ backgroundColor: "none", color: "green" }} /> : null}
                                    </Box>
                                    <span>{option.text}</span>
                                </MenuItem>
                            ))}
                        </Stack>
                    </div>
                )
            },
            {
                key: i18next.t("layout.logoutButtonLabel"),
                item: (
                    <React.Fragment>
                        <Divider />
                        <MenuItem
                            key={i18next.t("layout.logoutButtonLabel")}
                            onClick={() => {
                                handleClose();
                                handleLogout();
                            }}
                        >
                            <ListItemIcon>
                                <Logout style={{ backgroundColor: "none", color: "#3a81d4" }} />
                            </ListItemIcon>
                            {i18next.t("layout.logoutButtonLabel")}
                        </MenuItem>
                    </React.Fragment>
                )
            }
        ];
        setMenuItemList(isAdmin ? [...menuListItemValues] : [...menuListItemValues.slice(4)]);
    }, [isAdmin]);

    return (
        <Box className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <Tooltip
                    content={{
                        children: isSidebarOpen ? i18next.t("layout.closeDrawer") : i18next.t("layout.openDrawer"),
                        style: {
                            backgroundColor: "white"
                        }
                    }}
                    relationship="label"
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        style={{ position: "absolute", top: "22px", padding: "0px 30px" }}
                    >
                        {!isSidebarOpen ? <HistoryFilled className="clock-icon" /> : <AvTimer className="clock-icon" />}
                    </IconButton>
                </Tooltip>
                <div className={styles.headerContainer}>
                    <div className={styles.headerTitleContainer} style={{ margin: "0px 0px 0px 15%" }}>
                        <img src={wap} alt={i18next.t("layout.wap")} aria-label={i18next.t("layout.wap")} style={{ marginRight: "10px" }} />
                        <h3 className={styles.headerTitle}>{i18next.t("layout.headerTitle")}</h3>
                    </div>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "auto" }}>
                        <div style={{ padding: "5px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            {userInfo?.isWap === "True" && (
                                <React.Fragment>
                                    <div style={{ marginTop: "8px", marginLeft: "10px" }}>
                                        <Link
                                            style={{ textDecoration: "underline", alignSelf: "center" }}
                                            to=""
                                            onClick={() => {
                                                window.open(
                                                    "https://docs.google.com/spreadsheets/d/1p-MiAJxyiOjYwd-bTNjx152GmVfBXF8XvyIsK7q8aM0/edit?usp=sharing",
                                                    "_blank"
                                                );
                                            }}
                                        >
                                            <Tooltip
                                                content={{
                                                    children: i18next.t("layout.manual"),
                                                    style: {
                                                        backgroundColor: "white"
                                                    }
                                                }}
                                                relationship="label"
                                            >
                                                <MenuBook style={{ backgroundColor: "none", color: "white" }} />
                                            </Tooltip>
                                        </Link>
                                    </div>
                                    <div style={{ marginTop: "10px", marginLeft: "10px", marginRight: "10px" }}>
                                        <Link
                                            style={{ textDecoration: "underline", alignSelf: "center" }}
                                            to=""
                                            onClick={() => {
                                                window.open(
                                                    "https://docs.google.com/forms/d/e/1FAIpQLSd5GOsfVU7SriBrt3UAJhT-5UApFapLWa8HUnMd3GkvlppdhA/viewform?usp=sf_link",
                                                    "_blank"
                                                );
                                            }}
                                        >
                                            <Tooltip
                                                content={{
                                                    children: i18next.t("layout.feedbackForm"),
                                                    style: {
                                                        backgroundColor: "white"
                                                    }
                                                }}
                                                relationship="label"
                                            >
                                                <RateReview style={{ backgroundColor: "none", color: "white" }} />
                                            </Tooltip>
                                        </Link>
                                    </div>
                                </React.Fragment>
                            )}
                            <div style={{ marginTop: "8px", cursor: "pointer" }}>
                                <Tooltip
                                    content={{
                                        children: i18next.t("appTour.tourIcon"),
                                        style: {
                                            backgroundColor: "white"
                                        }
                                    }}
                                    relationship="label"
                                >
                                    <QuestionCircle24Filled onClick={handleTourStart} style={{ backgroundColor: "none", color: "white" }} />
                                </Tooltip>
                            </div>
                        </div>
                        <h3 style={{ marginRight: "8px" }} className={styles.headerTitle}>
                            {userSupportId}
                        </h3>
                        {
                            <React.Fragment>
                                <IconButton sx={{ marginTop: "8px" }} onClick={handleMenuClick}>
                                    <Tooltip
                                        content={{
                                            children: i18next.t("layout.screenMenuIconLabel"),
                                            style: {
                                                backgroundColor: "white"
                                            }
                                        }}
                                        relationship="label"
                                    >
                                        <NavigationFilled style={{ cursor: "pointer", color: "white", background: "none", fontSize: "1.5rem" }} />
                                    </Tooltip>
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    slotProps={{
                                        paper: {
                                            elevation: 0,
                                            sx: {
                                                overflow: "visible",
                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                mt: 1.5,
                                                "& .MuiAvatar-root": {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1
                                                },
                                                "&::before": {
                                                    content: '""',
                                                    display: "block",
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: "background.paper",
                                                    transform: "translateY(-50%) rotate(45deg)",
                                                    zIndex: 0
                                                }
                                            }
                                        }
                                    }}
                                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                >
                                    {menuListItems.map((menuItem: any) => (
                                        <div style={{ fontFamily: "inherit" }} key={menuItem.key}>
                                            {menuItem.item}
                                        </div>
                                    ))}
                                </Menu>
                            </React.Fragment>
                        }
                    </Box>
                </div>
            </header>

            <Outlet />
        </Box>
    );
};

export default Layout;
