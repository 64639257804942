import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";
import Skeleton from "@mui/material/Skeleton";
import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import i18next from "../../i18n/i18n";

interface Props {
    selectedAppType: string;
}

export const AnswerLoading = ({ selectedAppType }: Props) => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainer} style={{ display: "flex", flexDirection: "row" }} verticalAlign="center">
                <AnswerIcon
                    language={null}
                    sub_product={null}
                    product={null}
                    documenttype={null}
                    chatModel={null}
                    feedbackCorpId={null}
                    feedbackUser={null}
                    CorpId={null}
                    UserId={null}
                    isFeedbackPage={false}
                    productVersion={null}
                    apptype={null}
                />
                <Stack.Item grow>
                    <Tabs className={styles.answerText} value={selectedAppType} aria-label="basic tabs example">
                        <Tab label={i18next.t("chat.faq")} value="FAQ" className={styles["tab-root"]} />
                        <Tab label={i18next.t("chat.manuals")} value="Manuals" className={styles["tab-root"]} />
                    </Tabs>
                    <Box sx={{ width: "100%", marginTop: "5%" }}>
                        <Skeleton animation="wave" sx={{ width: "100%" }} />
                        <Skeleton animation="wave" sx={{ width: "90%" }} />
                        <Skeleton animation="wave" sx={{ width: "80%" }} />
                        <Skeleton animation="wave" sx={{ width: "70%" }} />
                        <Skeleton animation="wave" sx={{ width: "60%" }} />
                        <Skeleton animation="wave" sx={{ width: "50%" }} />
                    </Box>
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};
