import { useRef, useState, useEffect } from "react";
import styles from "./Chat.module.css";
import "./Filters.css";
import {
    ChatAppResponse,
    loadChatApi,
    loadUsersApi,
    loadFilteredSessionsApi,
    AdminFeedback,
    SaveResponse,
    feedbackApi,
    SessionInfo,
    YearData,
    AppType
} from "../../api";
import { AnswersHistory, Answer } from "../../components/Answer";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { Filters } from "../../components/Filters";
import { makeStyles, shorthands, Tab, Label } from "@fluentui/react-components";
import i18next from "i18next";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { AnswerRegenarate } from "../../components/Answer/AnswerRegenarate";
const useStyles = makeStyles({
    root: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        ...shorthands.padding("10px", "20px"),
        rowGap: "10px"
    },
    tabStyle: {
        color: "white",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        lineHeight: 2,
        fontSize: "15px",
        cursor: "pointer",
        ":active": {
            fontWeight: "bold"
        },
        ":selected": {
            fontWeight: "bold"
        },
        ":hover": {
            fontWeight: "bold"
        },
        ":target": {
            fontWeight: "bold"
        }
    }
});
interface Users {
    key: string;
    text: string;
    corp_id: string;
    corp_name: string;
}

interface Corps {
    key: string;
    text: string;
}

interface Sessions {
    session_id: string;
    title: string;
    user_id: string;
    corp_id: string;
    conversation_date: string;
}

interface Props {
    isSidebarOpen: boolean;
}

const primaryIDMap: Map<number, string> = new Map();
const masterList: {
    users: Users[];
    corps: Corps[];
    monthlySessions: any;
    todaySessionsSource: Sessions[];
    last7SessionsSource: Sessions[];
    last30SessionsSource: Sessions[];
} = {
    users: [],
    corps: [],
    monthlySessions: [],
    todaySessionsSource: [],
    last7SessionsSource: [],
    last30SessionsSource: []
};
const filteredData: { users: Users[]; corps: Corps[] } = {
    users: [],
    corps: []
};
const Feedback = (props: Props) => {
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(true);
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: { FAQ: ChatAppResponse; Manuals?: ChatAppResponse }][]>([]);

    const [todaySessionsRef, setTodaySessionsRef] = useState<[SessionInfo] | []>([]);
    const [last7SessionsRef, setLast7SessionsRef] = useState<[SessionInfo] | []>([]);
    const [last30SessionsRef, setLast30SessionsRef] = useState<[SessionInfo] | []>([]);
    const [monthlySessions, setMonthlySessions] = useState<YearData[]>([]);
    const [uniqueUsers, setUniqueUsers] = useState<{ key: string | number; text: string; corp_id: string }[]>([]);
    const [uniqueCorpIds, setUniqueCorpIds] = useState<{ key: string | number; text: string }[]>([]);
    const [currentIndex, setCurrentIndex] = useState<string>("");
    const [selectedCitation, setSelectedCitation] = useState(0);
    const [doctype, setDoctype] = useState<boolean>(false);
    const [selectedAppType, setSelectedAppType] = useState<AppType>((sessionStorage.getItem("selectedAppType") as AppType) ?? "FAQ");
    sessionStorage.setItem("selectedAppType", selectedAppType);

    const lastAnswerIndexRef = useRef<number>(-1);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const clearObject = { key: "clear", text: i18next.t("filters.clear"), corp_id: "clear" };

    const Spinner = () => {
        return (
            <div className="full-page-spinner">
                <div className="spinner"></div>
            </div>
        );
    };

    const loadChatData = async (sessionId: string) => {
        try {
            setIsLoading(true);
            await loadChatApi(sessionId)
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    setSelectedAnswer(0);
                    const updatedAnswers: any = result?.answers?.map(
                        (answer: [user: string, response: { FAQ: ChatAppResponse; Manuals?: ChatAppResponse }]) => {
                            const fAQcontext: any = answer[1]["FAQ"]?.choices[0].context;
                            if (fAQcontext) {
                                updateUserInfo(fAQcontext);
                            }
                            const manualsContext: any = answer[1]["Manuals"]?.choices[0].context;
                            if (manualsContext) {
                                updateUserInfo(manualsContext);
                            }
                            return answer;
                        }
                    );
                    setAnswers([...updatedAnswers]);

                    let privateDoctype = false;
                    result.answers.forEach((answer: any) => {
                        if (
                            answer[1][selectedAppType]?.choices[0].context.data_points.some(
                                (dataPoint: { doctype: string }) => dataPoint.doctype === "Internal"
                            )
                        ) {
                            privateDoctype = true;
                            return true;
                        }
                    });
                    setDoctype(privateDoctype);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const updateUserInfo = (context: any) => {
        const userEntry: any = masterList.users?.find((entry: any) => entry.key === context.userId);
        if (userEntry) {
            context.userId = userEntry.text;
        }
        const corpEntry: any = masterList.users?.find((entry: any) => entry.corp_id === context.corpId);
        if (corpEntry) {
            context.corpId = corpEntry.corp_name;
        }
        if (context?.feedback_user) {
            const feedbackUser: any = masterList.users?.find((entry: any) => entry.key === context.feedback_user);
            if (feedbackUser) {
                context.feedback_user = feedbackUser.text;
            }
            const feedbackCorpEntry: any = masterList.users?.find((entry: any) => entry.corp_id === context.feedback_corp_id);
            if (feedbackCorpEntry) {
                context.feedback_corp_id = feedbackCorpEntry.corp_name;
            }
        }
        return context;
    };

    const resetSessionData = (options: any) => {
        try {
            if (options.type === "all") {
                updateSessionStates({});
                setAnswers([]);
                setUniqueCorpIds(masterList.corps);
                setUniqueUsers([]);
            } else if (options.type === "user") {
                setUniqueUsers(filteredData.users);
                setUniqueCorpIds(filteredData.corps);
            } else {
                setUniqueCorpIds(masterList.corps);
                setUniqueUsers([]);
            }
        } catch (error) {
            console.error("Error resetting session data:", error);
        }
    };

    function applyFrontFilter(conditions: any) {
        const corpId = conditions["selectedCorpId"]?.key ?? null;
        const userId = conditions["selectedUsers"]?.key ?? null;
        setTodaySessionsRef(filterSessions(masterList.todaySessionsSource, corpId, userId));
        setLast7SessionsRef(filterSessions(masterList.last7SessionsSource, corpId, userId));
        setLast30SessionsRef(filterSessions(masterList.last30SessionsSource, corpId, userId));
        setMonthlySessions(filterYearData(masterList.monthlySessions, corpId, userId));
        return;
    }

    const handleApplyFilterClick = async (conditions: any) => {
        try {
            setIsLoading(true);
            await loadFilteredSessionsApi(conditions.filters)
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    updateSessionStates(result);
                    if (result["today"]?.length > 0 || result["7days"]?.length > 0 || result["30days"]?.length > 0) {
                        let corps: any = [];
                        let users: any = [];
                        let seenCorps = new Set();
                        masterList.users
                            .filter((item: any) => result["users_list"].includes(item.key))
                            .forEach((item: any) => {
                                if (!seenCorps.has(item.corp_id)) {
                                    corps.push({ key: item.corp_id, text: `[${item.corp_id}] ${item.corp_name}` });
                                    seenCorps.add(item.corp_id);
                                }
                                users.push({ key: item.key, text: item.text, corp_id: item.corp_id, corp_name: item.corp_name });
                            });
                        users.sort((a: any, b: any) => a.text.localeCompare(b.text));
                        filteredData.users = users;
                        filteredData.corps = corps;
                        if (conditions.isPrimaryFiltersChanged) {
                            setUniqueCorpIds(corps);
                        }
                        setUniqueUsers(users);
                    }
                    setAnswers([]);
                    setCurrentIndex("");
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterSessions = (sessions: any, corpId: string | null, userId: string | null) => {
        if (corpId && userId) {
            return sessions.filter((session: any) => session.corp_id === corpId && session.user_id === userId);
        } else if (corpId) {
            return sessions.filter((session: any) => session.corp_id === corpId);
        } else if (userId) {
            return sessions.filter((session: any) => session.user_id === userId);
        } else {
            return sessions;
        }
    };

    const filterYearData = (yearData: YearData[], corp_id: string, user_id: string): YearData[] => {
        return yearData
            .map(year => {
                const filteredSessions = year.sessions
                    .map(session => {
                        const filteredRecords = filterSessions(session.records, corp_id, user_id);
                        return filteredRecords.length > 0 ? { ...session, records: filteredRecords } : null;
                    })
                    .filter(session => session !== null);

                return filteredSessions.length > 0 ? { ...year, sessions: filteredSessions } : null;
            })
            .filter(year => year !== null);
    };

    const updateSessionStates = (result: any) => {
        setTodaySessionsRef(result["today"] ?? []);
        setLast7SessionsRef(result["7days"] ?? []);
        setLast30SessionsRef(result["30days"] ?? []);
        setMonthlySessions(result["months"] ?? []);
        masterList.todaySessionsSource = result["today"] ?? [];
        masterList.last7SessionsSource = result["7days"] ?? [];
        masterList.last30SessionsSource = result["30days"] ?? [];
        masterList.monthlySessions = result["months"] ?? [];
    };

    const updateUserList = (corp_id: string | null, loadFromMaster: boolean) => {
        const corpUsers: any = (loadFromMaster ? masterList.users : filteredData.users)?.filter((item: any) => item.corp_id === corp_id);
        corpUsers?.sort((a: any, b: any) => a.text.localeCompare(b.text));
        setUniqueUsers([clearObject, ...corpUsers]);
    };

    const getUniqueCorpIds = (items: any[]) => {
        return Array.from(new Set(items.map(item => item.corp_id)));
    };

    const getCorpDetails = (corp_id: any, items: any[]) => {
        const corp = items.find(item => item.corp_id === corp_id);
        return {
            key: corp_id,
            text: `[${corp_id}] ${corp.corp_name}`
        };
    };

    const loadUsers = async () => {
        try {
            await loadUsersApi()
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    const uniqueCorpIds = getUniqueCorpIds(result).map(corp_id => getCorpDetails(corp_id, result));
                    masterList.users = result;
                    masterList.corps = uniqueCorpIds;
                    setUniqueCorpIds(uniqueCorpIds);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const isValidUUID = (uuid: string) => {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidRegex.test(uuid);
    };

    useEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading];
        loadUsers();

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener("resize", handleResize);

        const params = new URLSearchParams(window.location.search);
        const sessionId = params.get("SessionId");

        if (sessionId && isValidUUID(sessionId)) {
            setCurrentIndex(sessionId);
            loadChatData(sessionId);
        } else {
            setCurrentIndex("");
            history.replaceState({}, "", "/assistant/feedbacks");
        }
    }, []);

    const onShowCitation = (citation: string, citationIndex: number, index: number) => {
        setActiveCitation(citation);
        setActiveAnalysisPanelTab(AnalysisPanelTabs.SupportingContentTab);
        setSelectedAnswer(index);
        setSelectedCitation(citationIndex);
    };

    const onFeedbackSubmitClicked = async (feedback: AdminFeedback, chat_id: string) => {
        try {
            const request: SaveResponse = {
                feedbacks: feedback
            };
            const response = await feedbackApi(request, chat_id, selectedAppType);
            if (!response.body) {
                throw Error("No response body");
            }
        } catch (e) {
            console.log("Feedback Save block failed");
        } finally {
            console.log("Feedback Save block end");
        }
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };
    const updateStreamedAnswer = (index: number, updatedAnswer: ChatAppResponse) => {
        const updatedStreamedAnswers = [...answers];
        if (index >= 0 && index < updatedStreamedAnswers.length) {
            updatedStreamedAnswers[index][1][selectedAppType] = updatedAnswer;
            setAnswers(updatedStreamedAnswers);
        }
    };
    const tabstyles = useStyles();
    const [secondDivHeight, setSecondDivHeight] = useState(0);
    const secondDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (secondDivRef.current) {
            setSecondDivHeight(secondDivRef.current.clientHeight);
        }
        const handleResize = () => {
            if (secondDivRef.current) {
                setSecondDivHeight(secondDivRef.current.clientHeight);
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className={styles.overall_div_feedback}>
            {isLoading && <Spinner />}
            {props.isSidebarOpen && (
                <Sidebar
                    sourcePage={"feedback"}
                    todaySessionsRef={todaySessionsRef}
                    last7SessionsRef={last7SessionsRef}
                    last30SessionsRef={last30SessionsRef}
                    monthlySessions={monthlySessions}
                    isSidebarOpen={props.isSidebarOpen}
                    isLoading={isLoading}
                    currentIndex={currentIndex}
                    loadChatData={(session_id: string) => {
                        loadChatData(session_id);
                    }}
                    setCurrentIndex={(session_id: string) => {
                        setCurrentIndex(session_id);
                    }}
                    nextDate={""}
                    loadSessionsData={() => {}}
                />
            )}
            <div
                className={styles.container}
                style={{
                    height: window.innerHeight - 218
                }}
            >
                <div className={styles.filters_container} ref={secondDivRef}>
                    <Filters
                        corpIdList={uniqueCorpIds}
                        usersList={uniqueUsers}
                        handleApplyFilterClick={filters => {
                            handleApplyFilterClick(filters);
                        }}
                        applyFrontFilter={values => {
                            applyFrontFilter(values);
                        }}
                        resetFilters={(type: any) => {
                            resetSessionData(type);
                        }}
                        updateUserList={(corpId: string, isApplied: boolean) => {
                            updateUserList(corpId, isApplied);
                        }}
                    />
                </div>
                <div
                    className={styles.chatRoot}
                    style={{
                        height: window.innerHeight - 168,
                        overflowY: "auto",
                        overflowX: "hidden",
                        marginTop: `${secondDivHeight}px`
                    }}
                >
                    <div className={styles.chatContainer}>
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} isAdminUser={true} selectedAppType={"FAQ"} />
                                    <div className={styles.chatMessageGpt}>
                                        {answer[1][selectedAppType] === undefined || answer[1][selectedAppType] === null ? (
                                            <div className={styles.chatMessageGptMinWidth}>
                                                <AnswerRegenarate
                                                    selectedAppType={selectedAppType}
                                                    isLoading={false}
                                                    isStreaming={false}
                                                    answer={answer[1]}
                                                    onRetry={() => {}}
                                                    setSelectedAppType={setSelectedAppType}
                                                    onTabClicked={() => {
                                                        setSelectedAnswer(index);
                                                    }}
                                                />
                                            </div>
                                        ) : answer[1][selectedAppType]?.choices[0].context.keywords !== undefined ||
                                          answer[1][selectedAppType]?.choices[0].context.feedback?.category === 0 ? (
                                            <Answer
                                                updateStreamedAnswer={updateStreamedAnswer}
                                                isStreaming={true}
                                                key={index}
                                                doctype={doctype}
                                                response={answer[1]}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                onCitationClicked={(c, citationIndex) => onShowCitation(c, citationIndex, index)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={undefined}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answer.length - 1 === index}
                                                onFeedbackSubmitClicked={(feedback: any, conv_id: string) => onFeedbackSubmitClicked(feedback, conv_id)}
                                                primaryId={index.toString()}
                                                onClickLikeDislike={() => {
                                                    lastAnswerIndexRef.current = index;
                                                }}
                                                isAdminUser={true}
                                                isLastAnswer={answers?.length === 0 || (answers?.length > 0 && answers?.length - 1 === index) ? true : false}
                                                isFeedbackPage={true}
                                                onSend={() => {}}
                                                selectedAppType={selectedAppType}
                                                setSelectedAppType={setSelectedAppType}
                                                lastAnswerIndexRefValue={lastAnswerIndexRef.current}
                                                onTabClicked={() => {
                                                    setSelectedAnswer(index);
                                                }}
                                                currentPageView={"history"}
                                            />
                                        ) : (
                                            <AnswersHistory
                                                key={index}
                                                primaryId={index}
                                                response={answer[1]}
                                                doctype={doctype}
                                                isStreaming={false}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                onCitationClicked={(c, citationIndex) => onShowCitation(c, citationIndex, index)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                isLastAnswer={answers?.length === 0 || (answers?.length > 0 && answers?.length - 1 === index) ? true : false}
                                                isFeedbackPage={true}
                                                onSend={() => {}}
                                                selectedAppType={selectedAppType}
                                                setSelectedAppType={setSelectedAppType}
                                                lastAnswerIndexRefValue={lastAnswerIndexRef.current}
                                                onTabClicked={() => {
                                                    setSelectedAnswer(index);
                                                }}
                                                currentPageView={"history"}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div style={{ position: "relative", top: "30%", fontSize: "xx-large", opacity: 0.5, fontStyle: "oblique", left: "30%" }}>
                                {answers.length == 0 && !isLoading && <Label>{i18next.t("filters.nodata")}</Label>}
                            </div>
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    </div>
                    {answers.length > 0 && activeAnalysisPanelTab && answers[selectedAnswer]?.[1]?.[selectedAppType] && (
                        <AnalysisPanel
                            className={styles.chatAnalysisPanel}
                            activeCitation={activeCitation}
                            onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                            citationHeight="810px"
                            answer={answers[selectedAnswer][1][selectedAppType]}
                            activeTab={activeAnalysisPanelTab}
                            index={selectedCitation}
                            isAdminUser={true}
                            onActiveContentChanged={citationIndex => {
                                setSelectedCitation(citationIndex);
                            }}
                            isStreaming={false}
                            sourcePage={"Feedback"}
                            appType={"FAQ"}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Feedback;
