import React, { useMemo, useState, useCallback, useEffect } from "react";
import { mergeStyleSets, Stack, IconButton, Popup, Layer, FocusTrapZone, DefaultButton, Checkbox, Icon, Text } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import i18next from "../../i18n/i18n";
import { ChatAppResponse, getCitationFilePath, Feedback, saveSharedChatValueApi, saveUserDetails, AppType, ChatSessionMode } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { useBoolean } from "@fluentui/react-hooks";
import { TextField } from "@fluentui/react/lib/TextField";
import { Separator } from "@fluentui/react/lib/Separator";
import { LockIcon } from "../LockIcon/LockIcon";
import { Toast, ToastTitle, Toaster, useId, useToastController } from "@fluentui/react-components";
import { appInfo, userInfo } from "../../authConfig";
import { useSetState } from "react-use";
import Joyride, { STATUS, Step } from "react-joyride";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { SelectedProductUnAvailableInfoMsgDialog } from "./SelectedProductUnAvailableInfoMsgDialog";

interface Props {
    response: any;
    updateStreamedAnswer?: any;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string, citationIndex: number) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string, appType: AppType) => void;
    onFeedbackSubmitClicked: (feedback: Feedback, conv_id: string) => void;
    showFollowupQuestions?: boolean;
    primaryId: string;
    onClickLikeDislike: () => void;
    isAdminUser?: boolean;
    doctype?: boolean;
    displayLink?: () => void;
    isLastAnswer: boolean;
    isFeedbackPage: boolean;
    onSend: (string: AppType) => void;
    isLoading?: boolean;
    selectedAppType: string;
    setSelectedAppType: (appType: AppType) => void;
    lastAnswerIndexRefValue: number;
    onTabClicked: () => void;
    currentPageView: ChatSessionMode;
    answerAreaRef?: any;
    chatResponseAreaRef?: any;
    lastQuestionIndexValue?: string;
    activateAnswerTour?: boolean;
    setActivateAnswerTour?: (tourFlag: boolean) => void;
}

interface LikeComponentProps {
    isLikePopupVisible: boolean;
    appType: string;
}

interface DisLikeComponentProps {
    isDisLikePopupVisible: boolean;
    appType: string;
}

interface ShareProps {
    isSharChatPopupVisible: boolean;
}

const popupStyles = mergeStyleSets({
    root: {
        background: "rgba(0, 0, 0, 0.5)",
        bottom: "0",
        left: "0",
        position: "absolute",
        right: "0",
        top: "0"
    },
    content: {
        background: "white",
        left: "50%",
        maxWidth: "1000px",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
        border: "2px solid",
        borderColor: "grey",
        padding: "15px"
    }
});

const stackTokens = { childrenGap: 10, padding: 25 };

let activeTab = "";

export const Answer = ({
    response,
    updateStreamedAnswer,
    isSelected,
    isStreaming,
    primaryId,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    onFeedbackSubmitClicked,
    showFollowupQuestions,
    onClickLikeDislike,
    doctype,
    isAdminUser,
    displayLink,
    isLastAnswer,
    isFeedbackPage,
    onSend,
    isLoading,
    selectedAppType,
    setSelectedAppType,
    lastAnswerIndexRefValue,
    onTabClicked,
    currentPageView,
    answerAreaRef,
    chatResponseAreaRef,
    lastQuestionIndexValue,
    activateAnswerTour,
    setActivateAnswerTour
}: Props) => {
    const answer: ChatAppResponse = response["FAQ"];
    const manualsAnswer: ChatAppResponse = response["Manuals"];
    const faqFollowupQuestions = answer?.choices[0]?.context?.followup_questions;
    const faqDataPoints = answer?.choices[0]?.context?.data_points ?? [];
    const faqMessageContent = typeof answer?.choices[0]?.message?.content === "string" ? answer?.choices[0]?.message?.content : "";
    const faqFileMapping = answer?.choices[0]?.context.file_mapping;
    const faqParsedAnswer = useMemo(() => parseAnswerToHtml(faqMessageContent, isStreaming, onCitationClicked, faqDataPoints, faqFileMapping), [answer]);
    const faqSanitizedAnswerHtml = DOMPurify.sanitize(faqParsedAnswer.answerHtml);

    const manualFollowupQuestions = manualsAnswer?.choices[0]?.context?.followup_questions;
    const manualDataPoints = manualsAnswer?.choices[0]?.context?.data_points ?? [];
    const manualMessageContent = typeof manualsAnswer?.choices[0]?.message?.content === "string" ? manualsAnswer?.choices[0]?.message?.content : "";
    const manualsFileMapping = manualsAnswer?.choices[0]?.context.file_mapping;
    const manualParsedAnswer = useMemo(
        () => parseAnswerToHtml(manualMessageContent, isStreaming, onCitationClicked, manualDataPoints, manualsFileMapping),
        [manualsAnswer]
    );
    const manualSanitizedAnswerHtml = DOMPurify.sanitize(manualParsedAnswer.answerHtml);

    const [isLikeVisible, { setTrue: showLike, setFalse: hideLike }] = useBoolean(true);
    const [isDisLikeVisible, { setTrue: showDisLike, setFalse: hideDisLike }] = useBoolean(true);
    const [isLikePopupVisible, { setTrue: showLikePopup, setFalse: hideLikePopup }] = useBoolean(false);
    const [isDisLikePopupVisible, { setTrue: showDisLikePopup, setFalse: hideDisLikePopup }] = useBoolean(false);
    const [isSharChatPopupVisible, { setTrue: showSharChatPopup, setFalse: hideShareChatPopup }] = useBoolean(false);
    const [likeIconName, setLikeIconName] = useState("Like");
    const [disLikeIconName, setDisLikeIconName] = useState("Dislike");
    const [shareIconName, setShareIconName] = useState("Share");
    const [isLikeDisabled, setIsLikeDisabled] = useState(false);
    const [isDisLikeDisabled, setIsDisLikeDisabled] = useState(false);
    const selectedProduct = (selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.product_filter;
    let conv_id_faq = answer?.choices[0]?.context.conv_id ?? "";
    let conv_id_manuals = manualsAnswer?.choices[0]?.context.conv_id ?? "";

    const [tabIndex, setTabIndex] = useState(selectedAppType + primaryId);
    const [selectedProductUnavailbleInfoDlgOpen, setSelectedProductUnavailbleInfoDlgOpen] = useState<boolean>(false);

    useEffect(() => {
        setTabIndex(selectedAppType + primaryId);
    }, [selectedAppType]);

    function copyToClipboard(href: string) {
        // Add UTM parameters to the chat share link
        const url = new URL(href);
        url.searchParams.set("utm_source", "aiworks");
        url.searchParams.set("utm_medium", "share_link");
        url.searchParams.set("utm_campaign", "chat_share");
        const chatShareLink = url.toString();
        const tempInput = document.createElement("input");
        tempInput.value = chatShareLink;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const notify = () =>
        dispatchToast(
            <Toast style={{ backgroundColor: "white", borderRadius: "5px", color: "green", width: "300px" }}>
                <ToastTitle style={{ color: "black", fontWeight: "bold" }}>{i18next.t("answer.chatLinkCopied")}</ToastTitle>
            </Toast>,
            { intent: "success" }
        );
    const saveSharedChatValue = async (sessionValue: string) => {
        try {
            await saveSharedChatValueApi(sessionValue, userInfo.corp_id)
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    console.log(result);
                });
        } catch (error) {
            console.error(error);
        }
    };

    function parseQueryParams(queryvalue: string) {
        const queryparameter = new URLSearchParams(queryvalue);
        const params: { [key: string]: string } = {};

        for (const [key, value] of queryparameter.entries()) {
            params[key] = value;
        }

        return params;
    }

    interface State {
        run: boolean;
        steps: Step[];
    }

    const [tourOne, setTourOne] = useSetState<State>({
        run: false,
        steps: [
            {
                content: i18next.t(`appTour.answerTour.shareIcon`),
                target: `#ShareIcon${lastQuestionIndexValue}`,
                disableBeacon: true,
                floaterProps: {
                    disableAnimation: true,
                    autoOpen: true
                }
            },
            {
                content: i18next.t(`appTour.answerTour.likeIcon`),
                target: `#LikeIcon${lastQuestionIndexValue}`
            },
            {
                content: i18next.t(`appTour.answerTour.disLikeIcon`),
                target: `#DisLikeIcon${lastQuestionIndexValue}`
            }
        ]
    });

    const [tourTwo, setTourTwo] = useSetState<State>({
        run: false,
        steps: [
            {
                content: i18next.t(`appTour.answerTour.faqTabInfo`),
                target: `[data-tour-id="FAQTab${lastQuestionIndexValue}"]`,
                disableBeacon: true,
                floaterProps: {
                    disableAnimation: true,
                    autoOpen: true
                },
                styles: {
                    tooltip: {
                        width: "200px",
                        maxWidth: "200px"
                    }
                }
            },
            {
                content: i18next.t(`appTour.answerTour.manualsTabInfo`),
                target: `[data-tour-id="ManualsTab${lastQuestionIndexValue}"]`
            }
        ]
    });

    const endTourOne = useCallback(() => {
        setTourOne(prevState => ({
            ...prevState,
            run: false
        }));
        if (userInfo.answerTourDone === "false") {
            saveUserSettings({ chatTourDone: "true", templateTourDone: "true", answerTourDone: "true", answerTabTourDone: "false" });
        }
        userInfo.answerTourDone = "true";
        setTimeout(() => {
            setTourTwo(prevState => ({
                ...prevState,
                run: true
            }));
        }, 1500);
    }, []);

    const endTourTwo = useCallback(() => {
        setTourTwo(prevState => ({
            ...prevState,
            run: false
        }));
        if (userInfo.answerTabTourDone === "false") {
            saveUserSettings({ chatTourDone: "true", templateTourDone: "true", answerTourDone: "true", answerTabTourDone: "true" });
        }
        userInfo.answerTabTourDone = "true";
    }, []);

    const handleJoyrideCallback = (data: any, isTabTour: boolean) => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            if (isTabTour) {
                endTourTwo();
                if (setActivateAnswerTour) {
                    setActivateAnswerTour(false);
                }
            } else {
                endTourOne();
            }
        } else {
            // status in running -> means tour is active.
            if (answerAreaRef.current && status === STATUS.RUNNING) {
                setTimeout(() => {
                    adjustScroll(isTabTour);
                }, 1000);
            }
        }
    };

    const adjustScroll = (isTabTour: boolean) => {
        if (answerAreaRef.current && chatResponseAreaRef.current) {
            answerAreaRef.current.scrollTop = isTabTour
                ? chatResponseAreaRef.current.offsetTop - answerAreaRef.current.offsetTop - 50
                : answerAreaRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        if (activateAnswerTour || isLastAnswer) {
            // need to update the tour guide to point to last question.
            setTourOne(prevState => ({
                ...prevState,
                run: false,
                steps: [
                    {
                        content: i18next.t(`appTour.answerTour.shareIcon`),
                        target: `#ShareIcon${lastQuestionIndexValue}`,
                        disableBeacon: true,
                        floaterProps: {
                            disableAnimation: true,
                            autoOpen: true
                        }
                    },
                    {
                        content: i18next.t(`appTour.answerTour.likeIcon`),
                        target: `#LikeIcon${lastQuestionIndexValue}`
                    },
                    {
                        content: i18next.t(`appTour.answerTour.disLikeIcon`),
                        target: `#DisLikeIcon${lastQuestionIndexValue}`
                    }
                ]
            }));
            setTourTwo(prevState => ({
                ...prevState,
                run: false,
                steps: [
                    {
                        content: i18next.t(`appTour.answerTour.faqTabInfo`),
                        target: `[data-tour-id="FAQTab${lastQuestionIndexValue}"]`,
                        disableBeacon: true,
                        floaterProps: {
                            disableAnimation: true,
                            autoOpen: true
                        },
                        styles: {
                            tooltip: {
                                width: "200px",
                                maxWidth: "200px"
                            }
                        }
                    },
                    {
                        content: i18next.t(`appTour.answerTour.manualsTabInfo`),
                        target: `[data-tour-id="ManualsTab${lastQuestionIndexValue}"]`
                    }
                ]
            }));
        }

        if (isStreaming === false && activateAnswerTour) {
            // scroll to last answer
            if (userInfo.answerTourDone === "false" && userInfo.answerTabTourDone === "false") {
                adjustScroll(false);
                setTimeout(() => {
                    setTourOne(prevState => ({
                        ...prevState,
                        run: true
                    }));
                }, 2500);
            } else if (userInfo.answerTourDone === "true" && userInfo.answerTabTourDone === "false") {
                adjustScroll(true);
                setTimeout(() => {
                    setTourTwo(prevState => ({
                        ...prevState,
                        run: true
                    }));
                }, 2500);
            } else {
                // tour was activate using btn click when all the tours are done.
                adjustScroll(false);
                setTimeout(() => {
                    setTourOne(prevState => ({
                        ...prevState,
                        run: true
                    }));
                }, 2500);
            }
        }

        if (isStreaming === false && !activateAnswerTour) {
            // when Answer private dlg is active don't activate the app tour.
            const isAnswerPrivateDlgActive = localStorage.getItem("private_warning") === "true" && localStorage.getItem("show_warning") === "true";
            if (!isAnswerPrivateDlgActive) {
                if (userInfo.answerTourDone === "false" && userInfo.answerTabTourDone === "false") {
                    adjustScroll(false);
                    setTimeout(() => {
                        setTourOne(prevState => ({
                            ...prevState,
                            run: true
                        }));
                    }, 2500);
                } else if (userInfo.answerTourDone === "true" && userInfo.answerTabTourDone === "false") {
                    adjustScroll(true);
                    setTimeout(() => {
                        setTourTwo(prevState => ({
                            ...prevState,
                            run: true
                        }));
                    }, 2500);
                }
            }
        }
    }, [lastQuestionIndexValue, isStreaming, activateAnswerTour, isLastAnswer]);

    const saveUserSettings = async (settings: object) => {
        try {
            await saveUserDetails(settings)
                .then(response => {
                    return response.json();
                })
                .catch(error => {
                    console.error("Error saving user data:", error);
                });
        } catch (error) {
            console.error("Error saving user data:", error);
        }
    };

    const validateProductKeyAvailableForHistoryView = (appTypeValue: AppType, newtabIndex: string) => {
        if ((appTypeValue === "FAQ" && faqSanitizedAnswerHtml !== "") || (appTypeValue === "Manuals" && manualSanitizedAnswerHtml !== "")) {
            setTabIndex(newtabIndex);
            return true;
        } else {
            // if any answer is empty.
            let targetKeyValue: string | undefined = "";
            let targetKeyType: string | undefined = "";
            if (appTypeValue === "FAQ" && faqSanitizedAnswerHtml === "") {
                targetKeyValue = selectedProduct?.manual_key;
                targetKeyType = "Manuals";
            }

            if (appTypeValue === "Manuals" && manualSanitizedAnswerHtml === "") {
                targetKeyValue = selectedProduct?.faq_key;
                targetKeyType = "FAQ";
            }

            const selectedLocale = sessionStorage.getItem("selectedLocale") ?? "ja";
            const filteredSelectedProduct = appInfo.product_categories[selectedLocale]?.filter((item: any) =>
                targetKeyType === "FAQ" ? item.faq_key === targetKeyValue : item.manual_key === targetKeyValue
            )[0];
            if (
                (appTypeValue === "FAQ" && filteredSelectedProduct?.faq_key === "") ||
                (appTypeValue === "Manuals" && filteredSelectedProduct?.manual_key === "")
            ) {
                setSelectedProductUnavailbleInfoDlgOpen(true);
                return false;
            } else {
                setSelectedProductUnavailbleInfoDlgOpen(false);
                setTabIndex(newtabIndex);
                return true;
            }
        }
    };

    const handleChangeTabIndex = (event: React.SyntheticEvent, newtabIndex: string) => {
        if (!String(window.location.pathname).includes("/assistant/feedbacks") && (isLoading || isStreaming)) {
            // not feedback page & page is loading or streaming.
            event.preventDefault();
        } else {
            if (!String(window.location.pathname).includes("/assistant/feedbacks")) {
                // not feedback page then check conditions below
                if (currentPageView === "chat") {
                    // for chat view or new chat -> selected product contains both the faqkey value & manual key value on streaming answers or answers object.
                    if (
                        (newtabIndex.includes("FAQ") && selectedProduct?.faq_key === "") ||
                        (newtabIndex.includes("Manuals") && selectedProduct?.manual_key === "")
                    ) {
                        setSelectedProductUnavailbleInfoDlgOpen(true);
                        return;
                    }
                    setTabIndex(newtabIndex);
                } else {
                    // page view is history.
                    // the answers object have only only on key either faq key or manual key for existing answers.
                    const appTypeValue = newtabIndex.includes("FAQ") ? "FAQ" : "Manuals";
                    validateProductKeyAvailableForHistoryView(appTypeValue, newtabIndex);
                }
            } else {
                // feedback page means simply switch the tab.
                setTabIndex(newtabIndex);
            }
        }
    };

    const LikeComponent: React.FC<LikeComponentProps> = ({ isLikePopupVisible, appType }) => {
        const [TextFieldLikeValue, setTextFieldLikeValue] = useState("");

        const handleLikeShowHide = () => {
            setTextFieldLikeValue("");
            hideLikePopup();
        };

        return isLikePopupVisible ? (
            <Layer>
                <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={handleLikeShowHide}>
                    <FocusTrapZone>
                        <div role="document" className={popupStyles.content}>
                            <div>
                                <IconButton style={{ color: "green" }} iconProps={{ iconName: "LikeSolid" }} />
                                <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>{i18next.t("answer.additionalFeedback")}</span>
                                <IconButton style={{ color: "black", float: "right" }} iconProps={{ iconName: "Cancel" }} onClick={handleLikeShowHide} />
                            </div>
                            <Separator></Separator>
                            <div>
                                <TextField
                                    autoFocus
                                    className={styles.thumbTextBox}
                                    multiline
                                    resizable={false}
                                    placeholder={i18next.t("answer.goodFeedbackText")}
                                    onChange={(e: any) => {
                                        setTextFieldLikeValue(e.target.value);
                                    }}
                                    value={TextFieldLikeValue}
                                />
                            </div>

                            <DefaultButton
                                style={{ borderRadius: "5px", marginLeft: "40%", padding: "5px" }}
                                onClick={() => {
                                    hideLikePopup();
                                    hideDisLike();
                                    setLikeIconName("LikeSolid");
                                    setIsLikeDisabled(true);

                                    const newAnswer = { ...(appType === "FAQ" ? answer : manualsAnswer) };
                                    if (newAnswer.choices[0].context.feedbackgiven !== undefined) {
                                        newAnswer.choices[0].context.feedbackgiven = true;
                                        newAnswer.choices[0].context.feedbacktype = "Positive";
                                    }
                                    if (newAnswer.choices[0].context.feedback?.category !== undefined) {
                                        newAnswer.choices[0].context.feedback.category = 1;
                                        newAnswer.choices[0].context.feedbacktype = "Positive";
                                    }
                                    updateStreamedAnswer(primaryId, newAnswer, appType);
                                    onFeedbackSubmitClicked(
                                        {
                                            text: TextFieldLikeValue,
                                            type: "up-vote",
                                            options: []
                                        },
                                        appType === "FAQ" ? conv_id_faq : conv_id_manuals
                                    );
                                }}
                            >
                                {i18next.t("answer.submitButton")}
                            </DefaultButton>
                        </div>
                    </FocusTrapZone>
                </Popup>
            </Layer>
        ) : null;
    };

    const DisLikeComponent: React.FC<DisLikeComponentProps> = ({ isDisLikePopupVisible, appType }) => {
        const [isCheckBoxOneChecked, setIsCheckBoxOneChecked] = useState(false);
        const [isCheckBoxTwoChecked, setIsCheckBoxTwoChecked] = useState(false);
        const [isCheckBoxThreeChecked, setIsCheckBoxThreeChecked] = useState(false);
        const [TextFieldDisLikeValue, setTextFieldDisLikeValue] = useState("");
        const [fieldDownVoteValue, setfieldDownVoteValue] = useState<number[]>([]);

        const onChangeTextFieldValue = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setTextFieldDisLikeValue(newValue || "");
            fnDisableSubmitButton();
        }, []);

        const fnDisableSubmitButton = () => {
            if ((isCheckBoxOneChecked || isCheckBoxTwoChecked || isCheckBoxThreeChecked) && TextFieldDisLikeValue.trim().length > 0) return false;
            else return true;
        };

        const updateCheckedValue = (isChecked: boolean, checkedValue: number) => {
            setfieldDownVoteValue(prev => {
                if (!isChecked) {
                    return [...prev, checkedValue];
                } else {
                    return prev.filter(value => value !== checkedValue);
                }
            });
        };

        function _onChangeCheckBoxone(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
            setIsCheckBoxOneChecked(!isCheckBoxOneChecked);
            updateCheckedValue(isCheckBoxOneChecked, 1);
        }

        function _onChangeCheckBoxtwo(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
            setIsCheckBoxTwoChecked(!isCheckBoxTwoChecked);
            updateCheckedValue(isCheckBoxTwoChecked, 2);
        }

        function _onChangeCheckBoxthree(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
            setIsCheckBoxThreeChecked(!isCheckBoxThreeChecked);
            updateCheckedValue(isCheckBoxThreeChecked, 3);
        }
        const handleDisLikeShowHide = () => {
            setTextFieldDisLikeValue("");
            setIsCheckBoxOneChecked(false);
            setIsCheckBoxTwoChecked(false);
            setIsCheckBoxThreeChecked(false);
            setfieldDownVoteValue([]);
            hideDisLikePopup();
        };

        return isDisLikePopupVisible ? (
            <Layer>
                <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={handleDisLikeShowHide}>
                    <FocusTrapZone>
                        <div role="document" className={popupStyles.content}>
                            <div>
                                <IconButton style={{ color: "red" }} iconProps={{ iconName: "DisLikeSolid" }} />
                                <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>{i18next.t("answer.additionalFeedback")}</span>
                                <IconButton style={{ color: "black", float: "right" }} iconProps={{ iconName: "Cancel" }} onClick={handleDisLikeShowHide} />
                            </div>
                            <Separator></Separator>
                            <div>
                                <TextField
                                    autoFocus
                                    className={styles.thumbTextBox}
                                    multiline
                                    resizable={false}
                                    placeholder={i18next.t("answer.badFeedbackText")}
                                    onChange={onChangeTextFieldValue}
                                    value={TextFieldDisLikeValue}
                                />
                                <Stack tokens={stackTokens}>
                                    <Checkbox label={i18next.t("answer.notTrue")} onChange={_onChangeCheckBoxone} checked={isCheckBoxOneChecked} />
                                    <Checkbox label={i18next.t("answer.notHelpful")} onChange={_onChangeCheckBoxtwo} checked={isCheckBoxTwoChecked} />
                                    <Checkbox label={i18next.t("answer.harmful")} onChange={_onChangeCheckBoxthree} checked={isCheckBoxThreeChecked} />
                                </Stack>
                            </div>

                            <DefaultButton
                                style={{ borderRadius: "5px", marginLeft: "40%", padding: "5px" }}
                                onClick={() => {
                                    hideDisLikePopup();
                                    hideLike();
                                    setDisLikeIconName("DislikeSolid");
                                    setIsDisLikeDisabled(true);
                                    const newAnswer = { ...(appType === "FAQ" ? answer : manualsAnswer) };
                                    if (newAnswer.choices[0].context.feedbackgiven !== undefined) {
                                        newAnswer.choices[0].context.feedbackgiven = true;
                                        newAnswer.choices[0].context.feedbacktype = "Negative";
                                    }
                                    if (newAnswer.choices[0].context.feedback?.category !== undefined) {
                                        newAnswer.choices[0].context.feedback.category = 1;
                                        newAnswer.choices[0].context.feedbacktype = "Negative";
                                    }
                                    updateStreamedAnswer(primaryId, newAnswer, appType);
                                    onFeedbackSubmitClicked(
                                        {
                                            text: TextFieldDisLikeValue,
                                            type: "down-vote",
                                            options: fieldDownVoteValue
                                        },
                                        appType === "FAQ" ? conv_id_faq : conv_id_manuals
                                    );
                                    displayLink ? displayLink() : null;
                                }}
                                disabled={fnDisableSubmitButton()}
                            >
                                {i18next.t("answer.submitButton")}
                            </DefaultButton>
                        </div>
                    </FocusTrapZone>
                </Popup>
            </Layer>
        ) : null;
    };

    const ShareComponent: React.FC<ShareProps> = ({ isSharChatPopupVisible }) => {
        if (!isSharChatPopupVisible) {
            return null;
        }
        return (
            <Layer>
                <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={hideShareChatPopup}>
                    <FocusTrapZone>
                        <div role="document" className={popupStyles.content} style={{ width: "500px" }}>
                            <div style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>
                                <Icon iconName="share" style={{ padding: "5px", fontSize: "20px" }} />
                                <span style={{ fontSize: "19px" }}>{i18next.t("answer.shareChat")}</span>
                                <IconButton style={{ color: "black", float: "right" }} iconProps={{ iconName: "Cancel" }} onClick={hideShareChatPopup} />
                            </div>
                            <div className={styles.thumbTextBox} style={{ display: "grid", justifyContent: "center", width: "465px" }}>
                                <label style={{ fontSize: "20px" }}>{i18next.t("answer.shareChatText")}</label>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <DefaultButton
                                    autoFocus
                                    iconProps={{ iconName: "link" }}
                                    style={{ color: "#1976d2", borderRadius: "5px", padding: "5px", fontSize: "13px" }}
                                    onClick={() => {
                                        const queryParams = parseQueryParams(location.search);
                                        const sessionValue = queryParams.SessionId;
                                        copyToClipboard(window.location.href);
                                        hideShareChatPopup();
                                        notify();
                                        saveSharedChatValue(sessionValue);
                                    }}
                                    text={i18next.t("answer.copyLink")}
                                ></DefaultButton>{" "}
                            </div>
                        </div>
                    </FocusTrapZone>
                </Popup>
            </Layer>
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {isLastAnswer && (
                <React.Fragment>
                    <Joyride
                        continuous
                        steps={tourOne.steps}
                        callback={data => {
                            if (!isStreaming) {
                                handleJoyrideCallback(data, false);
                            }
                        }}
                        run={tourOne.run}
                        hideCloseButton
                        scrollToFirstStep
                        showSkipButton
                        disableCloseOnEsc
                        disableOverlayClose
                        spotlightPadding={1}
                        locale={{
                            next: i18next.t(`appTour.next`),
                            skip: <strong>{i18next.t(`appTour.skip`)}</strong>,
                            back: i18next.t(`appTour.back`),
                            last: i18next.t(`appTour.last`)
                        }}
                    />
                    <Joyride
                        continuous
                        steps={tourTwo.steps}
                        callback={data => {
                            if (!isStreaming) {
                                handleJoyrideCallback(data, true);
                            }
                        }}
                        run={tourTwo.run}
                        hideCloseButton
                        scrollToFirstStep
                        showSkipButton
                        disableCloseOnEsc
                        disableOverlayClose
                        spotlightPadding={1}
                        locale={{
                            next: i18next.t(`appTour.next`),
                            skip: <strong>{i18next.t(`appTour.skip`)}</strong>,
                            back: i18next.t(`appTour.back`),
                            last: i18next.t(`appTour.last`)
                        }}
                    />
                </React.Fragment>
            )}
            <Stack
                id={primaryId}
                className={`${styles.answerContainer} ${isSelected && styles.selected}`}
                style={doctype ? { backgroundColor: "rgb(240, 255, 240)" } : {}}
                verticalAlign="space-between"
            >
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                        <div style={{ display: "inline-flex" }}>
                            <AnswerIcon
                                language={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.language}
                                documenttype={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.document_category}
                                chatModel={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.chat_model}
                                product={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.product_filter}
                                productVersion={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.productVersion}
                                sub_product={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.sub_product_filter}
                                feedbackCorpId={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.feedback_corp_id}
                                feedbackUser={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.feedback_user}
                                CorpId={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.corpId}
                                UserId={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.userId}
                                isFeedbackPage={isFeedbackPage}
                                apptype={selectedAppType}
                            />
                            {doctype && <LockIcon />}
                        </div>
                        <Box sx={{ width: "80%", typography: "body1", borderBottom: 1, borderColor: "divider" }}>
                            <TabContext value={tabIndex}>
                                <Box>
                                    <TabList onChange={handleChangeTabIndex} aria-label="lab API tabs example">
                                        <Tab
                                            key={"FAQ" + primaryId}
                                            data-tour-id={`FAQTab${primaryId}`}
                                            label={i18next.t("chat.faq")}
                                            value={"FAQ" + primaryId}
                                            className={styles["tab-root"]}
                                            onClick={() => {
                                                if (!String(window.location.pathname).includes("/assistant/feedbacks")) {
                                                    if (isLoading || isStreaming) {
                                                        return;
                                                    } else {
                                                        if (currentPageView === "chat") {
                                                            if (selectedProduct?.faq_key === "") {
                                                                setSelectedProductUnavailbleInfoDlgOpen(true);
                                                                return;
                                                            }
                                                        } else {
                                                            // history
                                                            const flag = validateProductKeyAvailableForHistoryView("FAQ", "FAQ" + primaryId);
                                                            if (!flag) {
                                                                return;
                                                            }
                                                        }
                                                        if (faqSanitizedAnswerHtml === "") {
                                                            onSend("FAQ");
                                                            activeTab = "FAQ" + primaryId;
                                                        }
                                                        setSelectedAppType("FAQ");
                                                    }
                                                } else {
                                                    setSelectedAppType("FAQ");
                                                }
                                                onTabClicked();
                                            }}
                                        />
                                        <Tab
                                            key={"Manuals" + primaryId}
                                            data-tour-id={`ManualsTab${primaryId}`}
                                            label={i18next.t("chat.manuals")}
                                            value={"Manuals" + primaryId}
                                            className={styles["tab-root"]}
                                            onClick={() => {
                                                if (!String(window.location.pathname).includes("/assistant/feedbacks")) {
                                                    if (isLoading || isStreaming) {
                                                        return;
                                                    } else {
                                                        if (currentPageView === "chat") {
                                                            if (selectedProduct?.manual_key === "") {
                                                                setSelectedProductUnavailbleInfoDlgOpen(true);
                                                                return;
                                                            }
                                                        } else {
                                                            // history
                                                            const flag = validateProductKeyAvailableForHistoryView("Manuals", "Manuals" + primaryId);
                                                            if (!flag) {
                                                                return;
                                                            }
                                                        }
                                                        if (manualSanitizedAnswerHtml === "") {
                                                            onSend("Manuals");
                                                            activeTab = "Manuals" + primaryId;
                                                        }
                                                        setSelectedAppType("Manuals");
                                                    }
                                                } else {
                                                    setSelectedAppType("Manuals");
                                                }
                                                onTabClicked();
                                            }}
                                        />
                                    </TabList>
                                </Box>
                            </TabContext>
                        </Box>
                        {["FAQ", "Manuals"].map(tabKey => {
                            const isFaq = tabKey === "FAQ";
                            const tabValue = tabKey + primaryId;
                            const parsedAnswer = isFaq ? faqParsedAnswer : manualParsedAnswer;
                            const selectedAnswer = isFaq ? answer : manualsAnswer;

                            return (
                                tabIndex === tabValue && (
                                    <Box key={tabValue} sx={{ display: "inline-flex" }}>
                                        {isAdminUser && (
                                            <IconButton
                                                style={{ color: "black" }}
                                                iconProps={{ iconName: "Lightbulb" }}
                                                title={i18next.t("answer.thoughtProcess")}
                                                ariaLabel={i18next.t("answer.thoughtProcess")}
                                                onClick={() => onThoughtProcessClicked()}
                                                disabled={!selectedAnswer?.choices[0].context.thoughts?.length}
                                            />
                                        )}
                                        {parsedAnswer?.citations?.length > 0 && (
                                            <IconButton
                                                style={{ color: "black" }}
                                                iconProps={{ iconName: "ClipboardList" }}
                                                title={i18next.t("answer.supportingContent")}
                                                ariaLabel={i18next.t("answer.supportingContent")}
                                                onClick={() => onSupportingContentClicked()}
                                                disabled={!selectedAnswer?.choices[0].context.data_points?.length}
                                            />
                                        )}
                                    </Box>
                                )
                            );
                        })}
                    </Stack>
                </Stack.Item>
                <TabContext value={tabIndex} key={primaryId}>
                    <TabPanel value={"FAQ" + primaryId} sx={{ padding: 0 }} key={"FAQ" + primaryId}>
                        {!String(window.location.pathname).includes("/assistant/feedbacks") &&
                        selectedProduct?.faq_key === "" &&
                        faqSanitizedAnswerHtml === "" ? (
                            <Text style={{ color: "#1976d2" }}>{i18next.t("answer.documentUnavailable")}</Text>
                        ) : isLoading && faqSanitizedAnswerHtml === "" && lastAnswerIndexRefValue === Number(primaryId) ? (
                            <Box sx={{ width: "100%" }}>
                                <Skeleton animation="wave" sx={{ width: "100%" }} />
                                <Skeleton animation="wave" sx={{ width: "90%" }} />
                                <Skeleton animation="wave" sx={{ width: "80%" }} />
                                <Skeleton animation="wave" sx={{ width: "70%" }} />
                                <Skeleton animation="wave" sx={{ width: "60%" }} />
                                <Skeleton animation="wave" sx={{ width: "50%" }} />
                            </Box>
                        ) : faqSanitizedAnswerHtml === "" && lastAnswerIndexRefValue !== Number(primaryId) ? (
                            <Box sx={{ width: "100%", marginTop: "5%", display: "flex", flexDirection: "row" }}>
                                <Text style={{ marginTop: "4px", color: "#1976d2" }}>{i18next.t("answer.regenerateResponseInfoText")}</Text>
                                <IconButton
                                    disabled={isLoading || isStreaming}
                                    style={{ color: "#1976d2", float: "right" }}
                                    iconProps={{ iconName: "Sync" }}
                                    onClick={() => {
                                        onSend("FAQ");
                                    }}
                                />
                            </Box>
                        ) : (
                            <>
                                <Stack.Item grow style={{ wordBreak: "break-word", wordWrap: "break-word" }}>
                                    <ReactMarkdown children={faqSanitizedAnswerHtml} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />
                                </Stack.Item>
                                {!!faqParsedAnswer.citations.length && (
                                    <Stack.Item>
                                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                            <span className={styles.citationLearnMore}>{i18next.t("answer.citations")}:</span>
                                            {faqParsedAnswer.citations.map((x, i) => {
                                                const path = getCitationFilePath(x);
                                                return (
                                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path, i)}>
                                                        {`${++i}. ${x.replace(/^[^/]*\//, "faq/")}`}
                                                    </a>
                                                );
                                            })}
                                        </Stack>
                                    </Stack.Item>
                                )}
                                {answer?.choices[0].context?.has_question_id && faqParsedAnswer && faqParsedAnswer.citations.length === 0 && (
                                    <Text style={{ color: "#1976d2" }}>{i18next.t("chat.appWarningInfoFAQ")}</Text>
                                )}
                                {!!faqFollowupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                                    <Stack.Item>
                                        <Stack
                                            horizontal
                                            wrap
                                            className={`${faqParsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
                                            tokens={{ childrenGap: 6 }}
                                        >
                                            <span className={styles.followupQuestionLearnMore}>{i18next.t("answer.followupQuestions")}:</span>
                                            {faqFollowupQuestions.map((x, i) => {
                                                return (
                                                    <a
                                                        key={i}
                                                        className={styles.followupQuestion}
                                                        title={x}
                                                        onClick={() => onFollowupQuestionClicked(x, "FAQ")}
                                                    >
                                                        {`${x}`}
                                                    </a>
                                                );
                                            })}
                                        </Stack>
                                    </Stack.Item>
                                )}
                                <Stack.Item>
                                    <Stack horizontal horizontalAlign="end">
                                        <IconButton
                                            id={`ShareIcon${primaryId}`}
                                            style={{ color: "black" }}
                                            iconProps={{ iconName: shareIconName }}
                                            title={i18next.t("chat.shareChat")}
                                            ariaLabel={i18next.t("chat.shareChatURL")}
                                            onClick={() => showSharChatPopup()}
                                        />
                                        {isLikeVisible && (
                                            <IconButton
                                                id={`LikeIcon${primaryId}`}
                                                style={{ color: "black" }}
                                                iconProps={{ iconName: likeIconName }}
                                                title={i18next.t("answer.good")}
                                                ariaLabel={i18next.t("answer.good")}
                                                onClick={() => {
                                                    showLikePopup();
                                                    onClickLikeDislike();
                                                }}
                                                disabled={isLikeDisabled}
                                            />
                                        )}
                                        {isDisLikeVisible && (
                                            <IconButton
                                                id={`DisLikeIcon${primaryId}`}
                                                style={{ color: "black" }}
                                                iconProps={{ iconName: disLikeIconName }}
                                                title={i18next.t("answer.bad")}
                                                ariaLabel={i18next.t("answer.bad")}
                                                onClick={() => {
                                                    showDisLikePopup();
                                                    onClickLikeDislike();
                                                }}
                                                disabled={isDisLikeDisabled}
                                            />
                                        )}
                                    </Stack>
                                </Stack.Item>
                                <ShareComponent isSharChatPopupVisible={isSharChatPopupVisible} />
                                <Toaster toasterId={toasterId} position="bottom-start" pauseOnHover pauseOnWindowBlur timeout={2000} />
                                <LikeComponent isLikePopupVisible={isLikePopupVisible} appType={"FAQ"} />
                                <DisLikeComponent isDisLikePopupVisible={isDisLikePopupVisible} appType={"FAQ"} />
                            </>
                        )}
                    </TabPanel>
                    <TabPanel value={"Manuals" + primaryId} sx={{ padding: 0 }} key={"Manuals" + primaryId}>
                        {!String(window.location.pathname).includes("/assistant/feedbacks") &&
                        selectedProduct?.manual_key === "" &&
                        manualSanitizedAnswerHtml === "" ? (
                            <Text style={{ color: "#1976d2" }}>{i18next.t("answer.documentUnavailable")}</Text>
                        ) : isLoading && manualSanitizedAnswerHtml === "" && lastAnswerIndexRefValue === Number(primaryId) ? (
                            <Box sx={{ width: "100%" }}>
                                <Skeleton animation="wave" sx={{ width: "100%" }} />
                                <Skeleton animation="wave" sx={{ width: "90%" }} />
                                <Skeleton animation="wave" sx={{ width: "80%" }} />
                                <Skeleton animation="wave" sx={{ width: "70%" }} />
                                <Skeleton animation="wave" sx={{ width: "60%" }} />
                                <Skeleton animation="wave" sx={{ width: "50%" }} />
                            </Box>
                        ) : manualSanitizedAnswerHtml === "" && lastAnswerIndexRefValue !== Number(primaryId) ? (
                            <Box sx={{ width: "100%", marginTop: "5%", display: "flex", flexDirection: "row" }}>
                                <Text style={{ marginTop: "4px", color: "#1976d2" }}>{i18next.t("answer.regenerateResponseInfoText")}</Text>
                                <IconButton
                                    disabled={isLoading || isStreaming}
                                    style={{ color: "#1976d2", float: "right" }}
                                    iconProps={{ iconName: "Sync" }}
                                    onClick={() => {
                                        onSend("Manuals");
                                    }}
                                />
                            </Box>
                        ) : (
                            <>
                                <Stack.Item grow style={{ wordBreak: "break-word", wordWrap: "break-word" }}>
                                    <ReactMarkdown children={manualSanitizedAnswerHtml} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />
                                </Stack.Item>

                                {!!manualParsedAnswer.citations.length && (
                                    <Stack.Item>
                                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                            <span className={styles.citationLearnMore}>{i18next.t("answer.citations")}:</span>
                                            {manualParsedAnswer.citations.map((x, i) => {
                                                const path = getCitationFilePath(x);
                                                const contentPath = x
                                                    .replace(/^product-manuals-\d+\//, "product-manuals/")
                                                    .replace(/^product-manuals\/[^\/]+\//, "product-manuals/");
                                                return (
                                                    <a key={i} className={styles.citation} title={contentPath} onClick={() => onCitationClicked(path, i)}>
                                                        {`${++i}. ${contentPath.replace(/^[^/]*\//, "product_manual/")}`}
                                                    </a>
                                                );
                                            })}
                                        </Stack>
                                    </Stack.Item>
                                )}
                                {manualsAnswer?.choices[0].context?.has_question_id && manualParsedAnswer && manualParsedAnswer.citations.length === 0 && (
                                    <Text style={{ color: "#1976d2" }}>{i18next.t("chat.appWarningInfoManual")}</Text>
                                )}
                                {!!manualFollowupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                                    <Stack.Item>
                                        <Stack
                                            horizontal
                                            wrap
                                            className={`${manualParsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
                                            tokens={{ childrenGap: 6 }}
                                        >
                                            <span className={styles.followupQuestionLearnMore}>{i18next.t("answer.followupQuestions")}:</span>
                                            {manualFollowupQuestions.map((x, i) => {
                                                return (
                                                    <a
                                                        key={i}
                                                        className={styles.followupQuestion}
                                                        title={x}
                                                        onClick={() => onFollowupQuestionClicked(x, "Manuals")}
                                                    >
                                                        {`${x}`}
                                                    </a>
                                                );
                                            })}
                                        </Stack>
                                    </Stack.Item>
                                )}
                                <Stack.Item>
                                    <Stack horizontal horizontalAlign="end">
                                        <IconButton
                                            id={`ShareIcon${primaryId}`}
                                            style={{ color: "black" }}
                                            iconProps={{ iconName: shareIconName }}
                                            title={i18next.t("chat.shareChat")}
                                            ariaLabel={i18next.t("chat.shareChatURL")}
                                            onClick={() => showSharChatPopup()}
                                        />
                                        {isLikeVisible && (
                                            <IconButton
                                                id={`LikeIcon${primaryId}`}
                                                style={{ color: "black" }}
                                                iconProps={{ iconName: likeIconName }}
                                                title={i18next.t("answer.good")}
                                                ariaLabel={i18next.t("answer.good")}
                                                onClick={() => {
                                                    showLikePopup();
                                                    onClickLikeDislike();
                                                }}
                                                disabled={isLikeDisabled}
                                            />
                                        )}
                                        {isDisLikeVisible && (
                                            <IconButton
                                                id={`DisLikeIcon${primaryId}`}
                                                style={{ color: "black" }}
                                                iconProps={{ iconName: disLikeIconName }}
                                                title={i18next.t("answer.bad")}
                                                ariaLabel={i18next.t("answer.bad")}
                                                onClick={() => {
                                                    showDisLikePopup();
                                                    onClickLikeDislike();
                                                }}
                                                disabled={isDisLikeDisabled}
                                            />
                                        )}
                                    </Stack>
                                </Stack.Item>
                                <ShareComponent isSharChatPopupVisible={isSharChatPopupVisible} />
                                <Toaster toasterId={toasterId} position="bottom-start" pauseOnHover pauseOnWindowBlur timeout={2000} />
                                <LikeComponent isLikePopupVisible={isLikePopupVisible} appType={"Manuals"} />
                                <DisLikeComponent isDisLikePopupVisible={isDisLikePopupVisible} appType={"Manuals"} />
                            </>
                        )}
                    </TabPanel>
                </TabContext>
            </Stack>
            {isLastAnswer && <Text className={styles.lastInfo}>{i18next.t("chat.responseInfo")}</Text>}
            {selectedProductUnavailbleInfoDlgOpen && (
                <SelectedProductUnAvailableInfoMsgDialog
                    showWarningFlag={selectedProductUnavailbleInfoDlgOpen}
                    warningMsg={
                        selectedAppType !== "FAQ"
                            ? i18next.t("answer.faqUnavailableForSelectedProduct")
                            : i18next.t("answer.manualsUnavailableForSelectedProduct")
                    }
                    onClose={() => setSelectedProductUnavailbleInfoDlgOpen(false)}
                />
            )}
        </div>
    );
};
