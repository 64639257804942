import { IconButton, Stack, Text } from "@fluentui/react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import i18next from "../../i18n/i18n";
import styles from "./Answer.module.css";
import { AppType } from "../../api";
import { useEffect, useState } from "react";
import { appInfo } from "../../authConfig";
interface Props {
    selectedAppType: string;
    isLoading: boolean;
    isStreaming: boolean;
    answer: any;
    onRetry: () => void;
    setSelectedAppType: (appType: AppType) => void;
    onTabClicked: () => void;
}

export const AnswerRegenarate = ({ selectedAppType, isLoading, isStreaming, answer, onRetry, setSelectedAppType, onTabClicked }: Props) => {
    const [productType, setProductType] = useState<{ manual_key: string; faq_key: string }>({
        manual_key: "0",
        faq_key: "0"
    });

    useEffect(() => {
        const productTypeValue = answer[selectedAppType === "FAQ" ? "Manuals" : "FAQ"].choices[0].context.product_filter;
        const selectedLocale = sessionStorage.getItem("selectedLocale") ?? "ja";
        const filteredSelectedProduct = appInfo.product_categories[selectedLocale]?.filter((item: any) =>
            selectedAppType !== "FAQ" ? item.faq_key === productTypeValue.faq_key : item.manual_key === productTypeValue.manual_key
        )[0];
        if (productTypeValue.manual_key === "" && productTypeValue.faq_key !== "") {
            productTypeValue.manual_key = filteredSelectedProduct.manual_key;
        }
        if (productTypeValue.manual_key !== "" && productTypeValue.faq_key === "") {
            productTypeValue.faq_key = filteredSelectedProduct.faq_key;
        }
        setProductType(productTypeValue);
    }, [answer, selectedAppType]);

    const handleTabChangeIndex = (event: React.SyntheticEvent, newValue: AppType) => {
        if (!String(window.location.pathname).includes("/assistant/feedbacks") && (isLoading || isStreaming)) {
            event.preventDefault();
        } else {
            setSelectedAppType(newValue);
        }
    };

    return (
        <Stack className={styles.answerContainer} style={{ display: "flex", flexDirection: "row" }}>
            <Stack.Item grow>
                <Tabs
                    className={styles.answerText}
                    onChange={handleTabChangeIndex}
                    value={selectedAppType}
                    aria-label="basic tabs example"
                    style={{ padding: "0px" }}
                >
                    <Tab
                        label={i18next.t("chat.faq")}
                        value="FAQ"
                        className={styles["tab-root"]}
                        onClick={() => {
                            onTabClicked();
                        }}
                    />
                    <Tab
                        label={i18next.t("chat.manuals")}
                        value="Manuals"
                        className={styles["tab-root"]}
                        onClick={() => {
                            onTabClicked();
                        }}
                    />
                </Tabs>
                <Box sx={{ width: "100%", marginTop: "5%", display: "flex", flexDirection: "row" }}>
                    <Text style={{ marginTop: "4px", color: "#1976d2" }}>
                        {i18next.t(
                            selectedAppType === "FAQ" && productType.faq_key === ""
                                ? "answer.faqUnavailableForSelectedProduct"
                                : selectedAppType === "Manuals" && productType.manual_key === ""
                                  ? "answer.manualsUnavailableForSelectedProduct"
                                  : !String(window.location.pathname).includes("/assistant/feedbacks")
                                    ? "answer.regenerateResponseInfoText"
                                    : "answer.responseNotGenerated"
                        )}
                    </Text>
                    {!String(window.location.pathname).includes("/assistant/feedbacks") &&
                        (selectedAppType === "FAQ" ? productType.faq_key !== "" : productType.manual_key !== "") && (
                            <IconButton
                                disabled={isLoading || isStreaming || (selectedAppType === "FAQ" ? productType.faq_key === "" : productType.manual_key === "")}
                                style={{ color: "#1976d2", float: "right" }}
                                iconProps={{ iconName: "Sync" }}
                                onClick={onRetry}
                            />
                        )}
                </Box>
            </Stack.Item>
        </Stack>
    );
};
